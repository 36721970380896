import { ClientError } from "graphql-request"

export function parseError(errorCode?: string | null): string {
  if (errorCode && errors[errorCode]) {
    return errors[errorCode]
  }
  return "Sorry, unknown error. Can you try again?"
}

export function extractErrorCode(error) {
  if (error instanceof ClientError) {
    return error.response?.errors?.[0].message.replace("[GraphQL] ", "")
  }
  return error.message.replace("[GraphQL] ", "")
}

export function getErrorMessage(error: any) {
  const extracted = extractErrorCode(error)
  return parseError(extracted)
}

export const errors = {
  "user-error:max-daily-demo-accounts-created-reached":
    "The maximum number of demo accounts for this IP address is reached.",

  "user-error:unauthorized": "Unauthorized action, missing a valid API key",

  "user-error:invalid-mimetype":
    "Unsupported file, please upload a jpeg, jpg or png file",
  "user-error:file-size-too-small": "This file is too small",
  "user-error:file-size-too-large": "This file is too large",
  "user-error:upload-not-found":
    "We couldn't find the uploaded file to enhance. Can you retry the upload?",

  "user-error:upload-signed-file-not-found":
    "We couldn't find the uploaded file to enhance. Can you retry the upload?",

  "user-error:enhanced-image-not-found":
    "Sorry, something went wrong on our side. Can you try again?",

  "user-error:enhanced-image-not-finished":
    "Sorry, this image is not enhanced in time. Can you try again?",
  "user-error:user-not-activated":
    "This user account has not yet been activated",
  "user-error:user-does-not-exist":
    "There was an error processing your request",
  "user-error:user-already-exists":
    "Are you sure you don't have an account already?",
  "user-error:user-not-found": "Invalid or unkown email address",
  "user-error:activation-key-invalid":
    "The activation key is invalid, contact us if the issue persists.",
  "user-error:user-already-activated":
    "This account has already been activated, try to login or reset your password",
  "user-error:auth-invalid-credentials":
    "Email address and/or password are incorrect",
  "user-error:is-current-email": "This email address is not available",

  "user-error:credits-limit-reached":
    "You've reached your credit limit. Explore our subscriptions for more options.",
  "user-error:credits-limit-reached-check-limit":
    "You've reached your credit limit, check your monthly spending limit under 'Billing'.",
  "user-error:exceeded-polling-time":
    "We couldn't process your request in time, please try again",
  "error:viesus-error-1": "The file failed to process. Please try again.",
  "error:viesus-error-2": "The file has already been enhanced.",
  "error:viesus-error-3":
    "Internal processing error. Please create a report in the feedback section. (code: 3)",
  "error:viesus-error-4":
    "Internal processing error. Please create a report in the feedback section. (code: 4)",
  "error:viesus-error-5":
    "Internal processing error. Please create a report in the feedback section. (code: 5)",
  "error:viesus-error-6":
    "The file is invalid. Please create a report in the feedback section. (code: 6)",
  "error:viesus-error-7":
    "Internal processing error. Please create a report in the feedback section. (code: 7)",
  "error:viesus-error-8":
    "Internal processing error. Please create a report in the feedback section. (code: 8)",
  "error:viesus-error-9":
    "Internal processing error. Please create a report in the feedback section. (code: 9)",
  "error:viesus-error-10":
    "Internal processing error. Please create a report in the feedback section. (code: 10)",
  "error:viesus-error-11":
    "Internal processing error. Please create a report in the feedback section. (code: 11)",
  "error:viesus-error-12":
    "Internal processing error. Please create a report in the feedback section. (code: 12)",
  "error:viesus-error-13":
    "Internal processing error. Please create a report in the feedback section. (code: 13)",
  "error:viesus-error-14":
    "Internal processing error. Please create a report in the feedback section. (code: 14)",
  "error:viesus-error-15":
    "Internal processing error. Please create a report in the feedback section. (code: 15)",
  "error:viesus-error-16":
    "Internal processing error. Please create a report in the feedback section. (code: 16)",
  "error:viesus-error-17":
    "Internal processing error. Please create a report in the feedback section. (code: 17)",
  "error:viesus-error-18":
    "Internal processing error. Please create a report in the feedback section. (code: 18)",
  "error:viesus-error-19":
    "Internal processing error. Please create a report in the feedback section. (code: 19)",
  "error:viesus-error-20":
    "Internal processing error. Please create a report in the feedback section. (code: 20)",
  "error:viesus-error-21":
    "Internal processing error. Please create a report in the feedback section. (code: 21)",
  "error:viesus-error-22":
    "Internal processing error. Please create a report in the feedback section. (code: 22)",
  "error:viesus-error-23":
    "Internal processing error. Please create a report in the feedback section. (code: 23)",
  "error:viesus-error-24":
    "Internal processing error. Please create a report in the feedback section. (code: 24)",
  "error:viesus-error-25":
    "Internal processing error. Please create a report in the feedback section. (code: 25)",
  "error:viesus-error-26":
    "Internal processing error. Please create a report in the feedback section. (code: 26)",
  "error:viesus-error-27":
    "Internal processing error. Please create a report in the feedback section. (code: 27)",
  "error:viesus-error-28":
    "Internal processing error. Please create a report in the feedback section. (code: 28)",
  "error:viesus-error-29":
    "Internal processing error. Please create a report in the feedback section. (code: 29)",
  "error:viesus-error-30":
    "Internal processing error. Please create a report in the feedback section. (code: 30)",
  "error:viesus-error-31":
    "Internal processing error. Please create a report in the feedback section. (code: 31)",
  "error:viesus-error-32":
    "Internal processing error. Please create a report in the feedback section. (code: 32)",
  "error:viesus-error-33":
    "Internal processing error. Please create a report in the feedback section. (code: 33)",
  "error:viesus-error-34":
    "Internal processing error. Please create a report in the feedback section. (code: 34)",
  "error:viesus-error-35":
    "Internal processing error. Please create a report in the feedback section. (code: 35)",
  "error:viesus-error-171":
    "Internal processing error. Please create a report in the feedback section. (code: 3)",
  "user-error:image-already-enhanced":
    "This image is already enhanced. Please try again with a new image",

  "user-error:cant-delete-default-api-key":
    "The default API key can not be deleted",
  "user-error:folder-has-queued-or-in-progress-enhancements":
    "This folder has queued or in progress enhancements, please wait until they are finished.",
  "user-error:enhanced-image-invalid-custom-width":
    "The selected custom width of this enhancement is too large for this image",

  "user-error:some-uploads-do-not-exist-or-do-not-belong-to-you":
    "Some uploads do not exist or belong to you",
  "user-error:exceed-max-delete-upload-count":
    "You can only delete 100 uploads at a time",
  "user-error:cannot-delete-uploads-that-are-in-progress":
    "Error deleting uploads. The file(s) are currently being queued for enhancement",
  "user-error:cannot-delete-pdfs-that-are-queuing-for-analysis":
    "Error deleting pdf(s). The file(s) are currently being queued for analysis",
}
